import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeWhile,
} from 'rxjs/operators';
import { Place } from '../../../gk-dynamic-form/services/place/place.model';
import { PlaceService } from '../../../gk-dynamic-form/services/place/place.service';
import { GkPostalCode } from '../../../gk-dynamic-form/services/postal-code/postal-code.model';
import { GkPostalCodeService } from '../../../gk-dynamic-form/services/postal-code/postal-code.service';
import { Street } from '../../../gk-dynamic-form/services/street/street.model';
import { StreetService } from '../../../gk-dynamic-form/services/street/street.service';
import { FormAlertService } from '../../../services/form-alert/form-alert.service';
import {
  LawPersonAddressControlName,
  MaxLengthAddressField,
} from '../../services/law-person-form/law-person-form.model';

@Component({
  selector: 'gk-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnDestroy, OnInit {
  private isAlive = true;
  @Input() form: FormGroup;
  @Input() isRequired: boolean;
  @Input() isLocalNumberRequired: boolean;
  @Input() isPostalCodeRequired: boolean;
  @Input() isBuildingNumberRequired = true;
  @Input() noStreetCheckbox: boolean;
  @Input() idPrefix = '';
  @Input() primitivePostalCode: boolean;
  @Input() wasFormValidated: boolean;
  @Input() newPersonMode: boolean;
  @Input() fieldsToHide: LawPersonAddressControlName[] = [];
  lawPersonAddressControlName = LawPersonAddressControlName;
  maxLengthAddressField = MaxLengthAddressField;

  constructor(
    private placeService: PlaceService,
    private streetService: StreetService,
    private postalCodeService: GkPostalCodeService,
    private formAlertService: FormAlertService,
  ) {}

  ngOnInit(): void {
    this.disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange();
  }

  disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange(): void {
    const streetAbsenceFormControl = this.getStreetAbsenceFormControl();
    if (!streetAbsenceFormControl) {
      return;
    }
    const streetFormControl = this.getStreetFormControl();
    streetAbsenceFormControl.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (value) {
          streetFormControl.disable();
          streetFormControl.setValue(null);
        } else {
          streetFormControl.enable();
        }
      });
  }

  searchPlace = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(Boolean),
      switchMap((term) => this.placeService.getPlacesByTerm(term)),
    );

  searchStreet = ($term: Observable<string>) =>
    $term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(Boolean),
      switchMap((term) =>
        this.streetService.getStreets(term, this.getPlaceId()),
      ),
    );

  getPlaceId(): number | undefined {
    const place = this.getPlaceFormControl().value;

    return place && place.id;
  }

  searchPostalCode = ($text: Observable<string>) =>
    $text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 5),
      switchMap((term) => this.postalCodeService.getPostalCodes(term)),
    );

  setPlaceByName(): void {
    const formControl = this.getPlaceFormControl();
    if (
      formControl?.value &&
      !_.get(formControl.value, 'id') &&
      _.isString(formControl.value)
    ) {
      this.placeService
        .getPlacesByTerm(formControl.value)
        .pipe(
          takeWhile(() => this.isAlive),
          takeWhile(
            () =>
              !_.get(formControl.value, 'id') && _.isString(formControl.value),
          ),
        )
        .subscribe((places) => {
          if (
            _.get(places, '[0].name', '').toUpperCase() ===
            formControl.value.toUpperCase()
          ) {
            formControl.setValue(places[0]);
          }
        });
    }
  }

  setStreetByName(): void {
    const formControl = this.getStreetFormControl();
    if (
      formControl?.value &&
      !_.get(formControl.value, 'id') &&
      _.isString(formControl.value)
    ) {
      this.streetService
        .getStreets(formControl.value, this.getPlaceId())
        .pipe(
          takeWhile(() => this.isAlive),
          takeWhile(
            () =>
              !_.get(formControl.value, 'id') && _.isString(formControl.value),
          ),
        )
        .subscribe((streets) => {
          if (
            _.get(streets, '[0].name', '').toUpperCase() ===
            formControl.value.toUpperCase()
          ) {
            formControl.setValue(streets[0]);
          }
        });
    }
  }

  setPostalCodeByName(): void {
    const formControl = this.getPostalCodeFormControl();
    if (
      formControl?.value &&
      !_.get(formControl.value, 'id') &&
      _.isString(formControl.value)
    ) {
      this.postalCodeService
        .getPostalCodes(formControl.value)
        .pipe(
          takeWhile(() => this.isAlive),
          takeWhile(
            () =>
              !_.get(formControl.value, 'id') && _.isString(formControl.value),
          ),
        )
        .subscribe((postalCodes) => {
          if (
            _.get(postalCodes, '[0].name', '').toUpperCase() ===
            formControl.value.toUpperCase()
          ) {
            formControl.setValue(postalCodes[0]);
          }
        });
    }
  }

  formatter = (dbModel: Place | Street | GkPostalCode): string => {
    return dbModel.name;
  };

  getPlaceFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.Place,
    ) as UntypedFormControl;
  }

  getStreetFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.Street,
    ) as UntypedFormControl;
  }

  getStreetAbsenceFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.StreetAbsence,
    ) as UntypedFormControl;
  }

  getBuildingNumberFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.BuildingNumber,
    ) as UntypedFormControl;
  }

  shouldShowPostalCodePatternErrorAlert(): boolean {
    return !!this.formAlertService.shouldShowErrorAlert(
      this.getPostalCodeFormControl(),
      'postalCodePattern',
      this.wasFormValidated,
    );
  }

  getPostalCodeFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.PostalCode,
    ) as UntypedFormControl;
  }

  getLocalNumberFormControl(): UntypedFormControl {
    return this.form.get(
      this.lawPersonAddressControlName.LocalNumber,
    ) as UntypedFormControl;
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.wasFormValidated,
    );
  }

  shouldShowStreetFromListErrorAlert(): boolean {
    return !!this.formAlertService.shouldShowErrorAlert(
      this.form,
      'streetFromDictionary',
      this.wasFormValidated,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
