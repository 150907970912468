import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { WindowRef } from '@progress/kendo-angular-dialog';
import {
  getGkKendoGeomFileSelectActionButtonConfig,
  IdentifiersValidateStatuses,
  ValidParcelIdsResult,
} from './gk-kendo-ids-file-select.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogManagerService } from '../../services/gk-dialog-manager.service';
import { ConfirmationConfigWithKeys } from '../../components/gk-confirm-window/gk-confirm-window.model';
import { FileExtension } from '../../../utils/files/files.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'gk-get-kendo-geom-file-select',
  templateUrl: './gk-kendo-ids-file-select.component.html',
  styleUrls: ['./gk-kendo-ids-file-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GkKendoIdsFileSelectComponent implements OnInit, OnDestroy {
  private isAlive = true;
  protected readonly IdentifiersValidateStatuses = IdentifiersValidateStatuses;
  protected readonly gkKendoGeomFileSelectActionButtonConfig =
    getGkKendoGeomFileSelectActionButtonConfig(this);
  windowRef: WindowRef;
  @ViewChild('dialogErrorLogContent') dialogErrorLogContent: TemplateRef<any>;
  fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.txt'],
  };
  selectedFiles: Array<File>;
  isValidFormat = false;
  isValidMaxNumberLimit = false;
  validationResult: ValidParcelIdsResult;
  readonly maxIdsNumberLimit = 500;
  tooMuchDataMessage: string;
  readonly parcelIdentifierRegExp =
    /^\d{6}_\d\.\d{4}(\.AR_\d{1,2}){0,1}\.\d{1,6}\/{0,1}\d{0,4}$/;

  constructor(
    private translateService: TranslateService,
    private dialogManagerService: DialogManagerService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.fetchTooMuchDataTranslateMessage();
  }

  fetchTooMuchDataTranslateMessage(): void {
    this.translateService
      .get('GK.MAP.TOO_MUCH_DATA')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.tooMuchDataMessage = `${data} ${this.maxIdsNumberLimit}`;
      });
  }

  public select(e: SelectEvent): void {
    e.files.forEach((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension !== FileExtension.Txt) {
        this.isValidFormat = false;

        return;
      }
      this.isValidFormat = true;
      const reader = new FileReader();

      reader.onload = (ev): void => {
        const result = ev.target.result as string;

        this.handleIdsListValidation(result);
      };

      reader.readAsText(file.rawFile);
    });
  }

  handleIdsListValidation(result: string): void {
    const idsList = this.convertStringToArray(result);
    this.validationResult = this.validateParcelIdentifiersArray(idsList);

    this.isValidMaxNumberList(idsList);
  }

  convertStringToArray(inputString: string): Array<string> {
    return inputString.split(/\r\n|\n/).filter(Boolean);
  }

  isValidMaxNumberList(ids: Array<string>): void {
    this.isValidMaxNumberLimit = ids.length <= this.maxIdsNumberLimit;
  }

  isValid(): boolean {
    return (
      this.isValidFormat &&
      this.isValidMaxNumberLimit &&
      this.validationResult.isValid !== IdentifiersValidateStatuses.Invalid
    );
  }

  validateParcelIdentifiersArray(
    identifiersArray: Array<string>,
  ): ValidParcelIdsResult {
    const validIdentifiers = identifiersArray.filter((identifier) =>
      this.parcelIdentifierRegExp.test(identifier),
    );
    const invalidIdentifiers = identifiersArray.filter(
      (identifier) => !this.parcelIdentifierRegExp.test(identifier),
    );

    return ValidParcelIdsResult.fromAppToApi(
      validIdentifiers,
      invalidIdentifiers,
    );
  }

  onLoadClick(): void {
    if (
      this.validationResult.isValid ===
      IdentifiersValidateStatuses.PartillyValid
    ) {
      this.showInfoAboutPartiallyValidNumbers();

      return;
    }
    if (this.validationResult.isValid === IdentifiersValidateStatuses.Valid) {
      this.windowRef.close(this.validationResult.validIdentifiers);
    }
  }

  showInfoAboutPartiallyValidNumbers(): void {
    const confirmConfig: ConfirmationConfigWithKeys = {
      titleKey: 'TOASTR.ERROR_INFO',
      content: this.dialogErrorLogContent,
      height: 380,
      confirmSuccessCallback: (): void => {
        this.windowRef.close(this.validationResult.validIdentifiers);
      },
    };
    this.dialogManagerService.showConfirmationWithComponent(confirmConfig);
  }

  getInvalidIdentifiers(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.validationResult.invalidIdentifiers.join('<br>'),
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
