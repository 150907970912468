<form [formGroup]="form" novalidate>
  <div class="row">
    @if (!fieldsToHide.includes(lawPersonAddressControlName.Place)) {
      <div class="col-lg-3 col-md-6 col-xs-12 my-1">
        <label [attr.for]="idPrefix + lawPersonAddressControlName.Place">{{
          'ADDRESS_FORM.PLACE' | translate
        }}</label>
        <input
          type="text"
          [name]="idPrefix + lawPersonAddressControlName.Place"
          [id]="idPrefix + lawPersonAddressControlName.Place"
          class="form-control form-control-sm"
          [maxlength]="maxLengthAddressField.Place"
          [ngClass]="{ required: isRequired }"
          [attr.aria-required]="isRequired"
          [ngbTypeahead]="searchPlace"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [formControlName]="lawPersonAddressControlName.Place"
          (focusout)="setPlaceByName()"
          [autocomplete]="undefined"
        />
        @if (shouldShowRequiredAlert(getPlaceFormControl())) {
          <div class="alert alert-danger" tabindex="0">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    }
    @if (!fieldsToHide.includes(lawPersonAddressControlName.Street)) {
      <div class="col-lg-5 col-md-6 col-xs-12 my-1">
        <label [attr.for]="idPrefix + lawPersonAddressControlName.Street">{{
          'ADDRESS_FORM.STREET' | translate
        }}</label>
        @if (!noStreetCheckbox && !getPlaceFormControl().disabled) {
          /
          <input
            type="checkbox"
            class="me-1"
            [id]="idPrefix + lawPersonAddressControlName.StreetAbsence"
            [name]="idPrefix + lawPersonAddressControlName.StreetAbsence"
            [formControlName]="lawPersonAddressControlName.StreetAbsence"
          />
          <label
            [attr.for]="idPrefix + lawPersonAddressControlName.StreetAbsence"
            >{{ 'ADDRESS_FORM.STREET_DISABLED' | translate }}</label
          >
        }
        <input
          type="text"
          [name]="idPrefix + lawPersonAddressControlName.Street"
          [id]="idPrefix + lawPersonAddressControlName.Street"
          class="form-control form-control-sm"
          [maxlength]="maxLengthAddressField.Street"
          [ngClass]="{ required: isRequired }"
          [attr.aria-required]="isRequired"
          [ngbTypeahead]="searchStreet"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [formControlName]="lawPersonAddressControlName.Street"
          (focusout)="setStreetByName()"
          [autocomplete]="undefined"
        />
        @if (shouldShowRequiredAlert(getStreetFormControl())) {
          <div class="alert alert-danger" tabindex="0">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
        @if (shouldShowStreetFromListErrorAlert()) {
          <div class="alert alert-danger px-md-1 px-lg-3">
            {{ 'ADDRESS_FORM.VALUE_FROM_LIST' | translate }}
          </div>
        }
      </div>
    }
    @if (!fieldsToHide.includes(lawPersonAddressControlName.BuildingNumber)) {
      <div class="col-lg-1 col-sm-4 col-xs-12 my-1">
        <label
          class="text-nowrap"
          [attr.for]="idPrefix + lawPersonAddressControlName.BuildingNumber"
          >{{ 'ADDRESS_FORM.BUILDING_NUMBER' | translate }}</label
        >
        <input
          type="text"
          [name]="idPrefix + lawPersonAddressControlName.BuildingNumber"
          [id]="idPrefix + lawPersonAddressControlName.BuildingNumber"
          class="form-control form-control-sm"
          [maxlength]="maxLengthAddressField.BuildingNumber"
          [ngClass]="{
            required: isBuildingNumberRequired !== false && isRequired === false
          }"
          [attr.aria-required]="
            isBuildingNumberRequired !== false && isRequired === false
          "
          [formControlName]="lawPersonAddressControlName.BuildingNumber"
          autocomplete="undefined"
        />
        @if (shouldShowRequiredAlert(getBuildingNumberFormControl())) {
          <div class="alert alert-danger px-md-0 text-md-center" tabindex="0">
            <span class="d-md-none">{{ 'FIELD_REQUIRED' | translate }}</span>
            <i
              class="fa fa-asterisk d-md-inline-block d-none"
              aria-hidden="true"
            ></i>
          </div>
        }
      </div>
    }
    @if (!fieldsToHide.includes(lawPersonAddressControlName.LocalNumber)) {
      <div class="col-lg-1 col-sm-4 col-xs-12 my-1">
        <label
          class="text-nowrap"
          [attr.for]="idPrefix + lawPersonAddressControlName.LocalNumber"
          >{{ 'ADDRESS_FORM.LOCAL_NUMBER' | translate }}</label
        >
        <input
          type="text"
          [name]="idPrefix + lawPersonAddressControlName.LocalNumber"
          [id]="idPrefix + lawPersonAddressControlName.LocalNumber"
          class="form-control form-control-sm"
          [maxlength]="maxLengthAddressField.LocalNumber"
          [ngClass]="{ required: isLocalNumberRequired }"
          [attr.aria-required]="isLocalNumberRequired"
          [formControlName]="lawPersonAddressControlName.LocalNumber"
          [autocomplete]="undefined"
        />
        @if (shouldShowRequiredAlert(getLocalNumberFormControl())) {
          <div class="alert alert-danger px-md-1 px-lg-3" tabindex="0">
            <span class="d-md-none">{{ 'FIELD_REQUIRED' | translate }}</span>
            <i
              class="fa fa-asterisk d-md-inline-block d-none"
              aria-hidden="true"
            ></i>
          </div>
        }
      </div>
    }
    @if (!fieldsToHide.includes(lawPersonAddressControlName.PostalCode)) {
      <div class="col-lg-2 col-sm-4 col-xs-12 my-1">
        <label
          class="text-nowrap"
          [attr.for]="idPrefix + lawPersonAddressControlName.PostalCode"
          >{{ 'ADDRESS_FORM.POSTAL_CODE' | translate }}</label
        >
        @if (!primitivePostalCode) {
          <input
            type="text"
            [name]="idPrefix + lawPersonAddressControlName.PostalCode"
            [id]="idPrefix + lawPersonAddressControlName.PostalCode"
            class="form-control form-control-sm"
            [maxlength]="maxLengthAddressField.PostalCode"
            [ngClass]="{ required: isRequired }"
            [attr.aria-required]="isRequired"
            [ngbTypeahead]="searchPostalCode"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            [formControlName]="lawPersonAddressControlName.PostalCode"
            (focusout)="setPostalCodeByName()"
            [autocomplete]="undefined"
          />
        } @else {
          <input
            type="text"
            [name]="idPrefix + lawPersonAddressControlName.PostalCode"
            [id]="idPrefix + lawPersonAddressControlName.PostalCode"
            placeholder="XX-XXX"
            [maxlength]="maxLengthAddressField.PostalCode"
            class="form-control form-control-sm"
            [ngClass]="{ required: isRequired }"
            [attr.aria-required]="isRequired"
            [formControlName]="lawPersonAddressControlName.PostalCode"
            autocomplete="undefined"
          />
          @if (shouldShowPostalCodePatternErrorAlert()) {
            <div class="alert alert-danger px-md-1 px-lg-3" tabindex="0">
              {{ 'ADDRESS_FORM.POSTAL_CODE_NOT_CORRECT' | translate }}
            </div>
          }
        }
        @if (shouldShowRequiredAlert(getPostalCodeFormControl())) {
          <div class="alert alert-danger px-md-1 px-lg-3" tabindex="0">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    }
  </div>
</form>
