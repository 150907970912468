<div class="container">
  <section class="mt-4">
    <div class="card mb-4 border-dark">
      <div class="card-header text-white bg-dark">
        {{ workspaceStateTranslations['HEADER'] }}
      </div>

      <div class="card-body">
        <button
          [disabled]="workspaceStateLoading"
          type="button"
          class="btn btn-sm btn-outline-primary me-1"
          (click)="saveWorkspaceState()"
        >
          <span class="fa fa-paperclip"></span>
          {{ workspaceStateTranslations['SAVE'] }}
        </button>

        <button
          [disabled]="workspaceStateLoading"
          type="button"
          class="btn btn-sm btn-outline-primary"
          (click)="loadWorkspaceState()"
        >
          <span class="fa fa-inbox"></span>
          {{ workspaceStateTranslations['RESTORE'] }}
        </button>
      </div>

      @if (workspaceMsgTxt) {
        <div class="card-footer">
          <div class="d-flex flex-column">
            <span [ngClass]="'text-' + workspaceMsgType">{{
              workspaceMsgTxt
            }}</span>
            @if (workspaceStateLoading) {
              <div class="d-flex justify-content-center my-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">{{
                    'LOADING' | translate
                  }}</span>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </section>

  <form
    (ngSubmit)="handleSubmit()"
    [formGroup]="formGroup"
    class="row"
    novalidate
  >
    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !combinedPersonFormComponent?.isValid()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'NEW_REQUEST.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          @if (applicantType !== undefined) {
            <gk-combined-person-form
              [lawPersonFormGroup]="getLawPersonFormGroup()"
              [forceApplicantType]="applicantType"
              [wasFormValidated]="submitted"
              [legalPersonSearchFieldsToHide]="[
                legalPersonControlName.TypeId,
                legalPersonControlName.Name,
                legalPersonControlName.KrsNumber
              ]"
              (selectedPersonChange)="onApplicantChange($event)"
            >
            </gk-combined-person-form>
          }
        </div>

        @if (submitted && !combinedPersonFormComponent?.isPersonChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'NEW_REQUEST.CLIENT_DATA_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <span class="badge bg-light text-dark me-2">1</span
          >{{ 'NEW_REQUEST.TASK_DETAILS' | translate }}
        </div>

        <div class="card-body row">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Par1">
              {{ 'NEW_REQUEST.PUBLIC_TASK_CASE_REFERENCE' | translate }}
            </label>
            <input
              type="text"
              [id]="controlName.PublicTaskCaseReference"
              [name]="controlName.PublicTaskCaseReference"
              [formControlName]="controlName.PublicTaskCaseReference"
              aria-required="true"
              class="form-control form-control-sm required"
            />
            @if (
              shouldShowErrorAlert(
                controlName.PublicTaskCaseReference,
                'required'
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          @if (shouldShowControl('Par1')) {
            <div class="mb-2">
              <label class="text-nowrap" [attr.for]="controlName.Par1">
                {{ 'NEW_REQUEST.TASK_DESCRIPTION' | translate }}
              </label>
              <textarea
                [id]="controlName.Par1"
                [name]="controlName.Par1"
                [formControlName]="controlName.Par1"
                aria-required="true"
                class="form-control form-control-sm required"
              ></textarea>
              @if (shouldShowErrorAlert(controlName.Par1, 'required')) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          }

          @if (shouldShowControl('Par2')) {
            <div class="mb-2">
              <label [attr.for]="controlName.Par2">{{
                'NEW_REQUEST.LEGAL_PROVISION' | translate
              }}</label>
              <mat-form-field class="w-100 mat-required mat-gk">
                <input
                  [id]="controlName.Par2"
                  [name]="controlName.Par2"
                  [formControlName]="controlName.Par2"
                  class="form-control form-control-sm required"
                  aria-required="true"
                  type="text"
                  aria-label="Number"
                  matInput
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  (optionSelected)="onValueSelected($event)"
                >
                  @for (
                    autocompleteItem of filteredLegalInterestAutocompleteItems
                      | async;
                    track autocompleteItem
                  ) {
                    <mat-option [value]="autocompleteItem">{{
                      autocompleteItem.value
                    }}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
              @if (shouldShowErrorAlert(controlName.Par2, 'required')) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          }
        </div>
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <span class="badge bg-light text-dark me-2">2</span
          >{{ 'NEW_REQUEST.SHARING_PURPOSE_REGISTRY_HEADER' | translate }}
        </div>

        <div class="card-body row">
          <div class="col-12">
            <label for="sharingPurposeDataSet" class="mb-0">
              {{ 'NEW_REQUEST.SHARING_PURPOSE_REGISTRY' | translate }}
            </label>

            <div class="form-group mb-0">
              <select
                [id]="controlName.PublicRegister"
                [name]="controlName.PublicRegister"
                [formControlName]="controlName.PublicRegister"
                class="form-select form-select-sm required"
                aria-required="true"
              >
                @for (
                  publicRegister of publicRegistersDictionary;
                  track publicRegister.id
                ) {
                  <option [value]="publicRegister.id">
                    {{ publicRegister.name }}
                  </option>
                }
              </select>

              @if (
                shouldShowErrorAlert(controlName.PublicRegister, 'required')
              ) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="col-12">
      <div
        class="card mb-4"
        [ngClass]="
          submitted && getInvalidToolType()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <span class="badge bg-light text-dark me-2">3</span>
          {{ 'NEW_REQUEST.CHOOSE_RANGE_TYPE' | translate }}
        </div>

        <div class="card-body">
          @if (isPersonalDataControlShouldBeVisible()) {
            <div class="d-flex align-items-end mb-3">
              <div class="form-check">
                <input
                  [id]="controlName.PersonalData"
                  [name]="controlName.PersonalData"
                  [formControlName]="controlName.PersonalData"
                  type="checkbox"
                  class="form-check-input"
                />
                <label
                  class="form-check-label"
                  [attr.for]="controlName.PersonalData"
                >
                  {{ 'NEW_REQUEST.PERSONAL_DATA' | translate }}
                </label>
              </div>
            </div>
          }
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="mt-3">
            @if (
              mapState.toolsState[toolType.LandParcel]?.isActive &&
              mapState.mapObjectTablesState[mapParcelsObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.CHOSEN_PARCELS' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapParcelsObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }

            @if (
              mapState.toolsState[toolType.District]?.isActive &&
              mapState.mapObjectTablesState[mapDistrictsObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.CHOSEN_DISTRICTS' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapDistrictsObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }

            @if (
              mapState.toolsState[toolType.AnyPolygon]?.isActive &&
              mapState.mapObjectTablesState[mapRangesObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.DRAWN_POLYGON' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapRangesObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>

        @if (getInvalidToolType(); as invalidToolType) {
          @if (submitted) {
            @switch (invalidToolType) {
              @case (toolType.AnyPolygon) {
                <div class="card-footer">
                  <div class="alert alert-danger">
                    {{ 'NEW_REQUEST.CHOOSE_ONE_POLYGON' | translate }}
                  </div>
                </div>
              }
              @case (
                [
                  toolType.District,
                  toolType.LandParcel,
                  emptyToolType
                ].includes(invalidToolType)
                  ? invalidToolType
                  : false
              ) {
                <div class="card-footer">
                  <div class="alert alert-danger">
                    {{ 'NEW_REQUEST.CHOOSE_RANGE' | translate }}
                  </div>
                </div>
              }
            }
          }
        }
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <span class="badge bg-light text-dark me-2">4</span
          >{{ 'NEW_REQUEST.PERIOD' | translate }}
        </div>

        <div class="card-body row">
          <div class="form-group mb-0">
            <app-date-range-picker
              [currentDateIsMinDate]="true"
              [minimumNumberOfDaysFromTodayInToDate]="1"
              [maximumNumberOfDaysFromTodayInToDate]="
                (settingsService.getHubSettings() | async)?.przpMaxDays
              "
              (dateRangeChanged)="handleDateRangeChanged($event)"
              [required]="true"
            ></app-date-range-picker>

            @if (shoulShowDateRangeAlert()) {
              <div class="alert alert-danger mt-1">
                {{ 'PERIOD_INVALID' | translate }}
              </div>
            }

            @if (shoulShowDatePastAlert()) {
              <div class="alert alert-danger mt-1">
                {{ 'PAST_DATE' | translate }}
              </div>
            }

            @if (shoulShowDateToSameAsTodayAlert()) {
              <div class="alert alert-danger mt-1">
                {{ 'DATA_TO_SAME_AS_TODAY' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    @if (!ommitAuthorisedPerson) {
      <section class="col-12">
        <div class="card mb-4 border-dark">
          <div class="card-header text-white bg-dark">
            {{
              'NEW_REQUEST.AUTHORIZED_PERSON_TO_REGISTRY_VIEW_HEADER'
                | translate
            }}
          </div>

          <div class="card-body row">
            <div class="col-12">
              <app-authorized-person-to-registry-view
                [isAuthorizedPersonWithLoggedUserDuplicatePrevent]="false"
                [(authorizedPersonsToRegistryView)]="
                  authorizedPersonsToRegistryView
                "
              >
              </app-authorized-person-to-registry-view>
            </div>
          </div>
          @if (submitted && !authorizedPersonsToRegistryView.length) {
            <div class="card-footer">
              <div class="alert alert-danger">
                {{ 'NEW_REQUEST.REQUIRED_AUTHORIZED_PERSON' | translate }}
              </div>
            </div>
          }
        </div>
      </section>
    }

    @if (!ommitAuthorisedPerson) {
      <section class="col-12">
        <div class="card mb-4 border-dark">
          <div class="card-header text-white bg-dark">
            {{ 'NEW_REQUEST.CHOOSE_ENABLEMENT_TYPE' | translate }}
          </div>

          <div class="card-body row">
            <div class="col-12">
              <app-enablement
                [submitted]="submitted || validatedToPreview"
                [addingPersonsDirectlyToDb]="false"
                [enablement]="initialEnablement"
                [hideLackEnablement]="true"
                [legalPersonOnly]="true"
                [searchLegalPersonCustomUrl]="
                  '/api/system/petenci/ospraw/search'
                "
                (enablementFormChange)="onEnablementFormChange($event)"
              >
              </app-enablement>
            </div>
          </div>
        </div>
      </section>
    }

    <div class="col-12">
      @if (submitted && !isFormValid()) {
        <div class="alert alert-danger my-2">
          {{ 'NEW_REQUEST.NOT_EVERY_FIELD_IS_FILLED' | translate }}
        </div>
      }

      @if (validatedToPreview && !isFormValidToPreview()) {
        <div class="alert alert-danger mt-2">
          {{ 'NEW_REQUEST.INCORRECTLY_FILLED_FIELDS_EXISTING' | translate }}
        </div>
      }

      @if (errorPreviewMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorPreviewMessage | translate }}</span>
        </div>
      }

      @if (downloadingPreviewFile) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }

      <button
        type="submit"
        [disabled]="docSignPending"
        class="btn btn-color-from-db my-3 me-2"
      >
        {{ 'NEW_REQUEST.SUBMIT_REQUEST_BUTTON' | translate }}
      </button>

      <button
        type="button"
        [disabled]="docSignPending"
        class="btn btn-color-from-db"
        (click)="handlePreviewNewRequest()"
      >
        {{ 'NEW_REQUEST.REQUEST_PREVIEW' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </div>
  </form>
</div>
