<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{ 'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.TITLE' | translate }}
    </h1>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isBuildingSectionValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.BUILDING' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          </div>
        </div>

        @if (submitted && !isBuildingSectionValid()) {
          <div class="card-footer">
            @if (!isOneMapObjectSelected()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SINGLE_BUILDING_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (isOneMapObjectSelected() && !isPlaceSelected()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.PLACE_REQUIRED'
                    | translate
                }}
              </div>
            }
            @if (isOneMapObjectSelected() && !isStreetSelected()) {
              <div class="alert alert-danger mt-2">
                {{
                  'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.STREET_REQUIRED'
                    | translate
                }}
              </div>
            }
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.DOCUMENTATION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SHIPPING_FORM_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestBodyValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary" [disabled]="docSignPending">
        {{ 'ADDRESS_NUMBER_REQUEST_PORTAL.NEW_REQUEST.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
